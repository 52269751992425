import { BotCondition } from "./botcondition"

export class UserBotCondition {
    user_bot_condition_id?: number
    uuid?: string
    user_bot_id?: number
    name?: string
    condition_type?: number // ConditionTypeEnum
    side?: number // TradeSideEnum
    interval?: string
    length?: number
    value1?: number
    value2?: number
    value3?: number
    value4?: number
    value5?: number
    value6?: number
    record_status?: number
    created_at?: string
    updated_at?: string

    bot_condition?: BotCondition
}
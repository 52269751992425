import { Component, NgZone, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { ToastrService } from 'ngx-toastr'
import { LoginResDto, LoginReqDto } from '../../../models/authentication'
import { AuthenticationService } from '../../../services/authentication.service'
import * as LogRocket from 'logrocket'
import { state } from '@angular/animations'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup
  isAuthenticated: boolean
  accounts: any
  currentAccount: any
  submitted: boolean = false
  saving: boolean = false
  returnUrl: string

  get email() { return this.loginForm.get('email') }
  get password() { return this.loginForm.get('password') }

  constructor(
    private route: ActivatedRoute,
    private router: Router, 
    private authenticationService: AuthenticationService, 
    private formBuilder: FormBuilder, 
    private zone: NgZone,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard'

    this.authenticationService.currentUser.subscribe(user => {
      this.isAuthenticated = (user && user.token != null)
    })

    if (this.isAuthenticated) {
      this.router.navigate(['/dashboard'])
    }

    this.setupForm()
  }

  setupForm() {
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.required]
    })
  }

  async login() {
    if (this.loginForm.valid) {
      this.submitted = true
      this.saving = true
      let loginUser = new LoginReqDto()
      loginUser.email = this.email.value.trim()
      loginUser.password = this.password.value

      this.authenticationService.login(loginUser)
        .subscribe((loggedUser: LoginResDto) => {
          if (loggedUser && loggedUser.result && loggedUser.user && loggedUser.user.token) {
            this.saving = false
            this.isAuthenticated = loggedUser.result

            LogRocket.identify(`${loggedUser.user.uuid}`, {
              email: loginUser.email,
            });

            this.toastr.success('Login successful')
            this.router.navigateByUrl(this.returnUrl)
          } else {
            this.toastr.error(`Login failed`)
            this.saving = false
          }
        }, (error: any) => {
          this.toastr.error(`Login failed`)
          this.saving = false
        })
    }
    else {
      this.submitted = true
    }
  }
}
import { OnInit, AfterViewInit, OnDestroy, Component, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { DialogBoxComponent } from '../../dialog-box/dialog-box.component'
import { TickersService } from 'src/app/services/tickers.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Utils } from 'src/app/utils';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-portfolio-holdings',
  templateUrl: './portfolio-holdings.component.html',
  styleUrls: ['./portfolio-holdings.component.css']
})

export class PortfolioHoldingsComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    private tickersService: TickersService, 
    private portfolioService: PortfolioService, 
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) { }

  tickerData: PortfolioSymbolDto[] = []
  dataSource = new MatTableDataSource(this.tickerData)
  displayedColumns: string[] = ['symbol', 'balance', 'price', 'changePct', 'usdtValue', 'audValue', 'changeAud', 'action'];

  usdAudRate = 1.3 // TODO: Get current USD/AUD rate
  portfolio?: PortfolioResDto
  portfolio_id = 0

  ngOnInit() {
    const id = Number(this.route.snapshot.paramMap.get('id'));
    if (id && !isNaN(id)) {
      this.portfolio_id = id
      this.getPortfolio(id)
    } else {
      const currentUser = this.authenticationService.currentUserValue
      if (currentUser && currentUser.default_portfolio_id) { 
        this.getPortfolio(currentUser.default_portfolio_id)
      }  
    } 
  }

  ngOnDestroy(): void {
  }

  @ViewChild(MatTable, {static:true}) symbolsTable: MatTable<any>
  @ViewChild(MatSort, {static: false}) sort: MatSort

  ngAfterViewInit() {
    this.dataSource.sort = this.sort
  }

  getPortfolio(id: number) {
    this.portfolioService.log(`getPortfolio(${id})`)

    var that = this
    that.portfolioService.getPortfolio(id)
    .subscribe(portfolioResDto => {
      if (!portfolioResDto || !portfolioResDto.portfolioDto) {
        return
      }

      that.portfolio = portfolioResDto

      if (!portfolioResDto.portfolioSymbolsDto ||  portfolioResDto.portfolioSymbolsDto.length < 1){
        return
      }

      // Remove USDT from list as USDT/USDT is an invalid Binance symbol
      var index = 0
      portfolioResDto.portfolioSymbolsDto.forEach(portfolioSymbol => {
        if (portfolioSymbol.symbol.toUpperCase() == "USDT") {
          portfolioResDto.portfolioSymbolsDto.splice(index, 1)
        }
        index = index + 1
      })

      
      that.dataSource.data = portfolioResDto.portfolioSymbolsDto

      // this.getSymbolPrice_proxy(portfolioResDto.portfolioSymbolsDto) // 5s  fast - binance proxy (no changePct)
      this.getTickerPrices(portfolioResDto.portfolioSymbolsDto) // 10s slow - library 'trading-indicator' (complete dataset)
      // this.getTickersData(tickers) // 15s very slow 
    },
    err => {
      console.error(err);
    })
  }

  syncPortfolio(){
    var that = this
    this.toastr.info(`Syncing portfolio`)
    this.portfolioService.syncPortfolio(this.portfolio_id)
      .subscribe(tickerRes => {
        this.toastr.success(`Portfolio synced ${new Date().toLocaleString()}`)

        that.getPortfolio(that.portfolio_id)
      });
  }

  portfolioAddSymbol(symbolName: string, symbolBalance: number): void {
    if (!symbolName) { 
      this.toastr.error('Invalid symbol')
      return 
    } else if (!symbolBalance) {
      this.toastr.error('Invalid balance')
      return 
    } else {
      this.toastr.info(`Adding ${symbolBalance} ${symbolName}`)
    }

    var tickerReq: PortfolioSymbolDto = {
      portfolio_id: this.portfolio_id,
      symbol: symbolName.trim().toUpperCase(),
      balance: symbolBalance
    }

    var that = this
    this.portfolioService.portfolioAddSymbol(tickerReq)
      .subscribe(tickerRes => {
        that.dataSource.data.push(tickerRes);
        this.toastr.success(`${tickerRes.balance} ${tickerRes.symbol} added`)

        that.getPortfolio(that.portfolio_id)
      });
  }

  updatePortfolioSymbol(ticker: PortfolioSymbolDto): void {
    if (!ticker || !ticker.symbol) { 
      this.toastr.error('Invalid symbol')
      return 
    } else if (!ticker.balance || ticker.balance == null || isNaN(ticker.balance)) {
      // this.toastr.error('Invalid balance')
      // return 
      ticker.balance = 0
    } else {
      this.toastr.info(`Updating ${ticker.balance} ${ticker.symbol}`)
    }

    var tickerReq: PortfolioSymbolDto = {
      id: ticker.id,
      symbol: ticker.symbol.trim().toUpperCase(), // Not used by the backend
      balance: ticker.balance
    }

    var that = this
    this.portfolioService.updatePortfolioSymbol(tickerReq)
      .subscribe(tickerRes => {
        this.toastr.success(`${tickerRes.balance} ${tickerRes.symbol} updated`)

        that.getPortfolio(that.portfolio_id)
      });
  }

  deletePortfolioSymbol(ticker: PortfolioSymbolDto): void {
    if (!ticker || !ticker.id) { 
      this.toastr.error('Invalid symbol')
      return 
    } else {
      this.toastr.info(`Deleting ${ticker.balance} ${ticker.symbol}`)
    }

    var that = this
    this.portfolioService.deletePortfolioSymbol(ticker.id)
      .subscribe(tickerRes => {
        this.toastr.success(`${tickerRes.balance} ${tickerRes.symbol} deleted`)

        that.getPortfolio(that.portfolio_id)
      });
  }

  // TODO: Move business logic to service
  getTickerPrices(tickersReqArr: PortfolioSymbolDto[]) {
    this.tickersService.log(`getTickerPrices()`)
    var that = this
    tickersReqArr.forEach(function(asset, index) {
      var ticker = asset.symbol //+ '/USDT'      
      that.tickersService.getTickerPrice(ticker)
      .subscribe(ticker => {
        if (!ticker.symbol || ticker.symbol.length < 2){
          console.error(`Invalid symbol ${ticker}`)
          return
        }
        const assetPrice = ticker.price
        const assetUsdtValue = (assetPrice * that.dataSource.data[index].balance)
        const assetAudValue = (assetUsdtValue * that.usdAudRate)

        const changePct = ticker.changePct
        const changeAud = (assetAudValue * (changePct/100))

        that.dataSource.data[index].price = assetPrice
        that.dataSource.data[index].usdtValue = assetUsdtValue
        that.dataSource.data[index].audValue = assetAudValue
        that.dataSource.data[index].changePct = changePct
        that.dataSource.data[index].changeAud = changeAud
      },
      err => {
        console.error(err);
      }
      )})
  }

  // TODO: Move business logic to service
  getSymbolPrice_proxy(tickersReqArr: PortfolioSymbolDto[]) {
    this.tickersService.log(`getSymbolPrice_proxy()`)
    var that = this
    tickersReqArr.forEach(function(asset, index) {
      var ticker = asset.symbol + 'USDT'      
      that.tickersService.getSymbolPrice_proxy(ticker)
      .subscribe(ticker => {
        if (!ticker.symbol || ticker.symbol.length < 2){
          console.error(`Invalid symbol ${ticker}`)
          return
        }
        
        const assetPrice = ticker.price
        const assetUsdtValue = (assetPrice * that.dataSource.data[index].balance)
        const assetAudValue = (assetUsdtValue * that.usdAudRate)
        const changePct = ticker.changePct

        that.dataSource.data[index].price = assetPrice
        that.dataSource.data[index].usdtValue = assetUsdtValue
        that.dataSource.data[index].audValue = assetAudValue
        that.dataSource.data[index].changePct = changePct
        that.dataSource.data[index].changeAud = 0
      },
      err => {
        console.error(err);
      }
      )})
  }

  selectedSymbol?: PortfolioSymbolDto
  onSelect(ticker: PortfolioSymbolDto) {
    this.selectedSymbol = ticker

    this.router.navigate([`/portfolio/symbol/details/${ticker.id}`])
  }

  openDialog(action, obj) {
    obj.action = action;
    const dialogRef = this.dialog.open(DialogBoxComponent, {
      width: '250px',
      data: obj
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result.event == 'Add'){
        this.addRowData(result.data);
      }else if(result.event == 'Update'){
        this.updateRowData(result.data);
      }else if(result.event == 'Delete'){
        this.deleteRowData(result.data);
      }
    });
  }

  addRowData(ticker){
    this.portfolioAddSymbol(ticker.symbol, ticker.balance)
  }

  updateRowData(ticker){
    this.updatePortfolioSymbol(ticker)
    this.dataSource.data = this.dataSource.data.filter((value,key)=>{
      if(value.id == ticker.id){
        value.symbol = ticker.symbol;
        value.balance = ticker.balance;
      }
      return true;
    });
  }

  deleteRowData(ticker){
    this.deletePortfolioSymbol(ticker)
    this.dataSource.data = this.dataSource.data.filter((value,key)=>{
      return value.id != ticker.id;
    });
  }

  // getWallet() {
  //   var that = this
  //   that.portfolioService.getWallet()
  //   .subscribe(tickers => {
  //     that.dataSource.data = tickers
  //   },
  //   err => {
  //     console.error(err);
  //   })
  // }

  // getAllTickersPrice() {
  //   this.tickersService.getAllTickersPrice()
  //     .subscribe(res => {
  //       this.tickerData = res;
  //     },
  //     err => {
  //       console.error(err);
  //   });
  // }

  // getTickersData(tickersReqArr: PortfolioSymbol[]) {
  //   const that = this
  //   that.tickersService.getTickersData(tickersReqArr) 
  //   .subscribe(tickers => {      
  //     let tickerSymbols_portfolio = tickersReqArr.map(a => a.symbol);

  //     for (var i = 0; i < tickers.length; i++) {
  //       const ticker_response = tickers[i]
  //       var ticker_response_symbol = ticker_response.symbol
  //       ticker_response_symbol = ticker_response_symbol.replace('/', '')
  //       ticker_response_symbol = ticker_response_symbol.replace('USDT', '')
        
  //       const ticker_portfolio_index = tickerSymbols_portfolio.indexOf(ticker_response_symbol)
  //       const ticker_portfolio = tickersReqArr[ticker_portfolio_index]
        
  //       if (ticker_response_symbol == ticker_portfolio.symbol) {
  //         ticker_response.balance = ticker_portfolio.balance

  //         const assetUsdtValue = (ticker_response.price * ticker_response.balance)
  //         const assetAudValue = (assetUsdtValue * that.usdAudRate)

  //         ticker_response.usdtValue = assetUsdtValue
  //         ticker_response.audValue = assetAudValue  

  //         // tickers[i] = ticker_response
  //       } else {
  //         console.error('Invalid index from tickerSymbols_portfolio')
  //       }
  //     }
  //     that.dataSource.data = tickers;
  //   })
  // }

  // ======== Material Table ========
  getTotalUsdtValue() {
    return this.dataSource.data.map(t => t.usdtValue || 0).reduce((previousValue, currentValue) => previousValue + currentValue, 0);
  }

  getTotalAudValue() {
    return this.dataSource.data.map(t => t.audValue || 0).reduce((previousValue, currentValue) => previousValue + currentValue, 0);
  }

  getTotalChangeAud() {
    return this.dataSource.data.map(t => t.changeAud || 0).reduce((previousValue, currentValue) => previousValue + currentValue, 0);
  }

  getAvgChangePct() {
    var tickers = this.dataSource.data
    var sum = 0;
    for (var i = 0; i < tickers.length; i++ ){
        sum += (tickers[i].changePct || 0)
    }
    var avg = ((sum/tickers.length)) || 0
    var avgRes = Utils.roundNumber(avg)

    return avgRes || 0
  }
}

import { Component, OnInit } from '@angular/core';
import { AuthenticatedUser } from 'src/app/models/authentication';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  user: AuthenticatedUser
  referrals: User[] = [] 

  constructor(
    private authenticationService: AuthenticationService,
    private profileService: ProfileService
  ){}

  ngOnInit() {
    this.getUserDetails()
  }

  getUserDetails(){
    const currentUser = this.authenticationService.currentUserValue
    if (currentUser) {
      var that = this
      this.profileService.getProfile().subscribe(result => {
        if (result) {
          that.user = result
          this.getUserReferrals()
        }
      })
    }
  }

  getUserReferrals(){
    var that = this
    this.profileService.getReferrals().subscribe(result => {
      if (result) {
        that.referrals = result
      }
    })
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common'
import { UserExchangeService } from 'src/app/services/userexchange.service';
import { UniswapBotDto } from 'src/app/dtos/uniswapbotdto';
import { UniswapInstance } from 'src/app/models/uniswapinstance';
import { UniswapBotService } from 'src/app/services/uniswapbot.service';
import { UniswapInstanceService } from 'src/app/services/uniswapinstance.service';

@Component({
  selector: 'app-uniswap-bot-add',
  templateUrl: './uniswap-bot-add.component.html',
  styleUrls: ['./uniswap-bot-add.component.css']
})
export class UniswapBotAddComponent implements OnInit {

  uniswapBot: UniswapBotDto
  uniswapInstances: UniswapInstance[]

  constructor(
    private uniswapBotService: UniswapBotService,
    private uniswapInstanceService: UniswapInstanceService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private location: Location
  ) { }

  ngOnInit() {
    this.loadDefaultUniswapBot()
    this.getUniswapInstances()
    this.getUniswapBot()
  }

  loadDefaultUniswapBot(){
    // User Bot
    const defaultUniswapBot = new UniswapBotDto()
    defaultUniswapBot.user_id = 0
    defaultUniswapBot.uniswap_instance_id = 0
    defaultUniswapBot.name = ""
    defaultUniswapBot.blockchain_api = ""
    defaultUniswapBot.token_address = ""
    defaultUniswapBot.buy_amount = 0
    defaultUniswapBot.sell_amount = 0
    defaultUniswapBot.gas_limit = 0
    defaultUniswapBot.slippage_pc = 0
    defaultUniswapBot.deadline_mins = 0
    defaultUniswapBot.min_delay = 0
    defaultUniswapBot.max_delay = 0
    defaultUniswapBot.buy_weight = 0
    this.uniswapBot = defaultUniswapBot
  }

  // To duplicate existing uniswapBot
  getUniswapBot(): void {
    const id = Number(this.route.snapshot.paramMap.get('id'))
    if (id && !isNaN(id) && id > 0) {
      this.uniswapBotService.getBot(id).subscribe(response => {
        this.uniswapBot = response
      })
    }
  }

  getUniswapInstances(){
    this.uniswapInstanceService.getInstances().subscribe(response => {
      if (response && response.length > 0) {
        this.uniswapInstances = response
      }
    })
  }

  addUniswapBot() {
    if (!this.uniswapBot.name) {
      this.toastr.error('Invalid Name')
      return
    } else if (!this.uniswapBot.blockchain_api) {
      this.toastr.error('Invalid blockchain_api')
      return
    } else if (!this.uniswapBot.token_address) {
      this.toastr.error('Invalid token_address')
      return
    } else if (!this.uniswapBot.buy_amount) {
      this.toastr.error('Invalid buy_amount')
      return
    } else if (!this.uniswapBot.sell_amount) {
      this.toastr.error('Invalid sell_amount')
      return
    } else if (!this.uniswapBot.gas_limit) {
      this.toastr.error('Invalid gas_limit')
      return
    } else if (!this.uniswapBot.slippage_pc) {
      this.toastr.error('Invalid slippage_pc')
      return
    } else if (!this.uniswapBot.deadline_mins) {
      this.toastr.error('Invalid deadline_mins')
      return
    } else if (!this.uniswapBot.min_delay) {
      this.toastr.error('Invalid min_delay')
      return
    } else if (!this.uniswapBot.max_delay) {
      this.toastr.error('Invalid max_delay')
      return
    } else if (!this.uniswapBot.buy_weight) {
      this.toastr.error('Invalid buy_weight')
      return
    }

    var uniswapInstanceId = undefined
    if (this.uniswapBot.uniswap_instance.uniswap_instance_id) {
      uniswapInstanceId = this.uniswapBot.uniswap_instance.uniswap_instance_id
    } else {
      this.toastr.error('Invalid uniswap_instance_id')
    }

    var formUniswapBot: UniswapBotDto = {
      user_id: this.uniswapBot.user_id,
      uniswap_instance_id: uniswapInstanceId,
      name: this.uniswapBot.name,
      blockchain_api: this.uniswapBot.blockchain_api,
      token_address: this.uniswapBot.token_address,
      buy_amount: this.uniswapBot.buy_amount,
      sell_amount: this.uniswapBot.sell_amount,
      gas_limit: this.uniswapBot.gas_limit,
      slippage_pc: this.uniswapBot.slippage_pc,
      deadline_mins: this.uniswapBot.deadline_mins,
      min_delay: this.uniswapBot.min_delay,
      max_delay: this.uniswapBot.max_delay,
      buy_weight: this.uniswapBot.buy_weight
    }

    // const uniswapBotAddResDto = new UniswapBotAddResDto()
    // uniswapBotAddResDto.uniswapBot = formUniswapBot
    // uniswapBotAddResDto.uniswapBotSymbols = this.selectedSymbols

    this.toastr.info(`Adding ${this.uniswapBot.name}`)
    var that = this
    this.uniswapBotService.addUniswapBot(formUniswapBot)
      .subscribe(response => {
        if (response && response.uniswap_bot_id > 0) {
          that.toastr.success(`${response.name} added`)
          // this.router.navigate([`/bot/details/${response.uniswap_bot_id}`])
          this.router.navigate([`/uniswapbots`])
        } else if (response && response.error) {
          that.toastr.error(response.error)
        } else {
          that.toastr.error(`Error adding bot`)
        }
      })
  }

  goBack(): void {
    this.location.back()
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { UserBotDto } from 'src/app/dtos/userbotdto';
import { Utils } from 'src/app/utils';

@Component({
  selector: 'app-bot-detail-header',
  templateUrl: './bot-detail-header.component.html',
  styleUrls: ['./bot-detail-header.component.css']
})
export class BotDetailHeaderComponent implements OnInit {

  @Input() userBot?: UserBotDto
  
  constructor() { }

  ngOnInit() {
  }

  // get last_start_date() {
  //   const date = Utils.timestampToDate(this.userBot.last_start)
  //   return date
  // }

}

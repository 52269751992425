import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { UserBotSignal } from 'src/app/models/userbotsignal'
import { BotSignalService } from 'src/app/services/botsignal.service'
import { Location } from '@angular/common'
import { Subject, interval, Subscription } from 'rxjs'
import { DataTableDirective } from 'angular-datatables'
import { BotService } from 'src/app/services/bot.service'
import { UserBotDto } from 'src/app/dtos/userbotdto'

@Component({
  selector: 'app-bot-signals',
  templateUrl: './bot-signals.component.html',
  styleUrls: ['./bot-signals.component.css']
})

export class BotSignalsComponent implements OnInit, OnDestroy {

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective
  dtOptions: DataTables.Settings = {}
  dtTrigger: Subject<any> = new Subject<any>()

  autoRefreshInterval: Subscription = new Subscription()

  userBot?: UserBotDto
  userBotSignals?: UserBotSignal[]

  constructor(
    private route: ActivatedRoute,
    private botSignalService: BotSignalService,
    private botService: BotService,
    private location: Location
  ) {}
  

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      order: []
    }

    this.getUserBotDetails()
  }

  ngAfterViewInit(): void {
    this.getUserBotSignals()
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe()
    this.autoRefreshInterval.unsubscribe()
  }

  getId() {
    return Number(this.route.snapshot.paramMap.get('id'))
  }

  // UI
  // get last_start_date(){
  //   if (!this.userBot.last_start) return ''
  //   return new Date(this.userBot.last_start).toLocaleDateString("en-AU")
  // }
  
  getUserBotSignals(): void {
    const id = this.getId()
    this.botSignalService.getBotSignals(id).subscribe(response => {
      this.userBotSignals = response
      this.dtTrigger.next()
    })
  }

  getUserBotDetails(): void {
    const id = this.getId()
    this.botService.getBot(id).subscribe(response => {
      this.userBot = response
    })
  }

  goBack(): void {
    this.location.back()
  }

  refresh(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy()
      // Call the dtTrigger to rerender again
      this.getUserBotSignals()
    })
    // window.location.reload()
  }

  toggleAutoRefresh(seconds: number) {
    this.autoRefreshInterval.unsubscribe()
    if (!isNaN(seconds) && seconds > 0) {
      this.enableAutoRefresh(seconds)
    }
  }

  enableAutoRefresh(seconds: number) {
    if (this.userBot && this.userBot.is_running) {
      this.autoRefreshInterval = interval(seconds*1000).subscribe(x => {
        this.refresh()
      })
    }
  }
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { DataTablesModule } from "angular-datatables";
import { ToastrModule } from 'ngx-toastr';
import { MaterialModule } from './modules/material.module';

import { PortfolioHoldingsComponent } from './components/portfolio/portfolio-holdings/portfolio-holdings.component';
import { MessagesComponent } from './components/messages/messages.component';
import { DialogBoxComponent } from './components/dialog-box/dialog-box.component';
import { PortfolioSymbolDetailComponent } from './components/portfolio-symbol-detail/portfolio-symbol-detail.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/account/login/login.component';
import { PortfoliosComponent } from './components/portfolio/portfolio-list/portfolio-list.component';
import { PortfolioAddComponent } from './components/portfolio/portfolio-add/portfolio-add.component';
import { BotListComponent } from './components/bot/bot-list/bot-list.component'
import { BotAddComponent } from './components/bot/bot-add/bot-add.component';
import { BotDetailComponent } from './components/bot/bot-detail/bot-detail.component';
import { BotSignalsComponent } from './components/bot/bot-signals/bot-signals.component';
import { BotActionsComponent } from './components/bot/bot-actions/bot-actions.component';
import { BotDetailHeaderComponent } from './components/bot/bot-detail-header/bot-detail-header.component';
import { BotLogsComponent } from './components/bot/bot-logs/bot-logs.component';
import { SignupComponent } from './components/account/signup/signup.component';
import { ProfileComponent } from './components/account/profile/profile.component';

import { BooleanPipe } from './common/boolean.pipe';
import { TradeSidePipe } from './common/tradeside.pipe';
import { UniswapEventPipe } from './common/uniswapevent.pipe';
import { DateTimestampPipe } from './common/datetimestamp.pipe';

import { DobleyeComponent } from './components/dobleye/dobleye.component';

import { UniswapBotListComponent } from './components/uniswap-bot/uniswap-bot-list/uniswap-bot-list.component';
import { UniswapBotDetailComponent } from './components/uniswap-bot/uniswap-bot-detail/uniswap-bot-detail.component';
import { UniswapBotActionsComponent } from './components/uniswap-bot/uniswap-bot-actions/uniswap-bot-actions.component';
import { UniswapBotTxComponent } from './components/uniswap-bot/uniswap-bot-txs/uniswap-bot-txs.component';
import { GamePlayerListComponent } from './components/uniswap-bot/game-player-list/game-player-list.component';
import { UniswapBotAddComponent } from './components/uniswap-bot/uniswap-bot-add/uniswap-bot-add.component';
import { TokenDetailComponent } from './components/uniswap-bot/token-detail/token-detail.component';
import { WithdrawComponent } from './components/funds/withdraw/withdraw.component';
import { NumberToFixedPipe } from './common/numbertofixed.pipe';

@NgModule({
  declarations: [
    AppComponent,
    PortfolioHoldingsComponent,
    MessagesComponent,
    DialogBoxComponent,
    PortfolioSymbolDetailComponent,
    HeaderComponent,
    FooterComponent,
    DashboardComponent,
    LoginComponent,
    PortfoliosComponent,
    PortfolioAddComponent,
    BotListComponent,
    BooleanPipe,
    TradeSidePipe,
    DateTimestampPipe,
    NumberToFixedPipe,
    UniswapEventPipe,
    BotAddComponent,
    BotDetailComponent,
    BotSignalsComponent,
    BotActionsComponent,
    BotDetailHeaderComponent,
    BotLogsComponent,
    SignupComponent,
    ProfileComponent,
    DobleyeComponent,
    UniswapBotAddComponent,
    UniswapBotListComponent,
    UniswapBotDetailComponent,
    UniswapBotActionsComponent,
    UniswapBotTxComponent,
    GamePlayerListComponent,
    TokenDetailComponent,
    WithdrawComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    DataTablesModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
  ],
  entryComponents: [DialogBoxComponent],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { fromEventPattern, Observable, of } from 'rxjs'
import { MessageService } from './message.service'
import { catchError, tap } from 'rxjs/operators'
import { AuthenticationService } from './authentication.service'
import { environment } from '../../environments/environment'
import { AuthenticatedUser } from '../models/authentication'

@Injectable({
  providedIn: 'root'
})
export class PortfolioService {

  headers: HttpHeaders
  currentUser: AuthenticatedUser

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private authenticationService: AuthenticationService
  ){
    this.headers = new HttpHeaders()
    this.authenticationService.currentUser.subscribe((user: AuthenticatedUser) => {
      this.currentUser = user

      if (user && user.token) {
        this.headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('Api-Key', `${environment.apiKey}`)
        .set('Authorization', `Bearer ${this.currentUser.token}`)
      }
    })
  }

  // User Portfolios
  getPortfolios(): Observable<Portfolio[]> {
    const url = `${environment.apiUrl}/portfolio`
    return this.http.get<Portfolio[]>(url, { headers: this.headers })
      .pipe(
        // tap((tickers: PortfolioSymbol[]) => this.log(`getPortfolio()`)),
        catchError(this.handleError<Portfolio[]>('getPortfolios()'))
      )
  }

  // Add Portfolio
  addPortfolio(portfolio: Portfolio): Observable<PortfolioResDto> {
    return this.http.post<PortfolioResDto>(`${environment.apiUrl}/portfolio`, portfolio, { headers: this.headers })
      .pipe(
        // tap((newPortfolio: PortfolioResDto) => this.log(`PortfolioAdd w/ id=${newPortfolio.id}`)),
        catchError(this.handleError<PortfolioResDto>('PortfolioAdd'))
      )
  }

  // User Portfolio Tickers
  getPortfolio(portfolio_id: number): Observable<PortfolioResDto> {
    const url = `${environment.apiUrl}/portfolio/${portfolio_id}/light`
    return this.http.get<PortfolioResDto>(url, { headers: this.headers })
      .pipe(
        // tap((tickers: PortfolioResDto) => this.log(`getPortfolio()`)),
        catchError(this.handleError<PortfolioResDto>('getPortfolio()'))
      )
  }

  // User Wallet Tickers
  getWallet(portfolio_id: number): Observable<PortfolioSymbolDto[]> {
    const url = `${environment.apiUrl}/portfolio/${portfolio_id}/full`
    return this.http.get<PortfolioSymbolDto[]>(url, { headers: this.headers })
      .pipe(
        tap((tickers: PortfolioSymbolDto[]) => this.log(`getWallet()`)),
        catchError(this.handleError<PortfolioSymbolDto[]>('getWallet()'))
      )
  }

  // Sync User Exchange - Portfolio
  syncPortfolio(portfolio_id: number): Observable<any[]> {
    const url = `${environment.apiUrl}/userexchange/${portfolio_id}/sync`
    return this.http.get<any[]>(url, { headers: this.headers })
      .pipe(
        catchError(this.handleError<any[]>('syncPortfolio()'))
      )
  }

  // Get One Symbol
  getPortfolioSymbol(symbolId: number): Observable<PortfolioSymbolDto> {
    const url = `${environment.apiUrl}/portfoliosymbol/${symbolId}`
    return this.http.get<PortfolioSymbolDto>(url, { headers: this.headers })
      .pipe(
        tap((tickers: PortfolioSymbolDto) => this.log(`getPortfolioSymbol(${symbolId})`)),
        catchError(this.handleError<PortfolioSymbolDto>('getPortfolioSymbol()'))
      )
  }

  // Add Symbol
  portfolioAddSymbol(ticker: PortfolioSymbolDto): Observable<PortfolioSymbolDto> {
    return this.http.post<PortfolioSymbolDto>(`${environment.apiUrl}/portfoliosymbol`, ticker, { headers: this.headers })
      .pipe(
        tap((newPortfolioSymbol: PortfolioSymbolDto) => this.log(`portfolioAddSymbol w/ id=${newPortfolioSymbol.id}`)),
        catchError(this.handleError<PortfolioSymbolDto>('portfolioAddSymbol'))
      )
  }

  // Update Symbol
  updatePortfolioSymbol(ticker: PortfolioSymbolDto): Observable<PortfolioSymbolDto> {
    return this.http.put<PortfolioSymbolDto>(`${environment.apiUrl}/portfoliosymbol`, ticker, { headers: this.headers })
      .pipe(
        tap((portfolioSymbol: PortfolioSymbolDto) => this.log(`updatePortfolioSymbol w/ id=${portfolioSymbol.id}`)),
        catchError(this.handleError<PortfolioSymbolDto>('updatePortfolioSymbol'))
      )
  }

  // Delete symbol
  deletePortfolioSymbol(id: number): Observable<PortfolioSymbolDto> {
    return this.http.delete<PortfolioSymbolDto>(`${environment.apiUrl}/portfoliosymbol/${id}`, { headers: this.headers })
      .pipe(
        tap((portfolioSymbol: PortfolioSymbolDto) => this.log(`deletePortfolioSymbol w/ id=${portfolioSymbol.id}`)),
        catchError(this.handleError<PortfolioSymbolDto>('deletePortfolioSymbol'))
      )
  }

  log(message: string) {
    this.messageService.add(`PortfolioService: ${message}`)
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
   private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error) // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`)

      // Let the app keep running by returning an empty result.
      return of(result as T)
    }
  }
}

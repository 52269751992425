import { Component, OnInit } from '@angular/core';
import { HealthObjectListResDto, HealthSummaryResDto } from 'src/app/models/dobleye/healthsummary';
import { DobleyeService } from 'src/app/services/dobleye.service';

@Component({
  selector: 'app-dobleye',
  templateUrl: './dobleye.component.html',
  styleUrls: ['./dobleye.component.css']
})
export class DobleyeComponent implements OnInit {

  // healthSummaryResDto: HealthSummaryResDto
  users: HealthObjectListResDto
  jobs: HealthObjectListResDto
  jobSubmissions: HealthObjectListResDto
  chats: HealthObjectListResDto
  chatMessages: HealthObjectListResDto
  deviceTokens: HealthObjectListResDto
  minVersionIosApp: HealthObjectListResDto
  userLocations: HealthObjectListResDto
  guestLocations: HealthObjectListResDto

  constructor(
    private dobleyeService: DobleyeService
  ){}

  ngOnInit() {
    this.getHealthSummary()
  }

  getHealthSummary(){
      this.dobleyeService.getHealthSummary().subscribe(result => {
        if (result) {
          // console.log(result);
          // this.healthSummaryResDto = result
          this.users = result.users
          this.jobs = result.jobs
          this.jobSubmissions = result.jobSubmissions
          this.chats = result.chats
          this.chatMessages = result.chatMessages
          this.deviceTokens = result.deviceTokens
          this.minVersionIosApp = result.minVersionIosApp
          this.userLocations = result.userLocations
          this.guestLocations = result.guestLocations
        }
      })
  }

  isSuccess(healthObjectListResDto: HealthObjectListResDto){
    if (Number.parseInt(healthObjectListResDto.lastDayCount) > 0) {
      return "alert-success"
    } 
    return "alert-danger"
  }

  isLessThanXDays(dateString: string, days: number){
    const today = new Date()
    const daysAgo = new Date(today)
    daysAgo.setDate(daysAgo.getDate() - days)

    const lastModified = new Date(dateString)

    if (lastModified > daysAgo){
      return ""
    } 

    const doubleDaysAgo = new Date(today)
    doubleDaysAgo.setDate(doubleDaysAgo.getDate() - (days*2))
    
    if (lastModified > (doubleDaysAgo)){
      return "alert-warning"
    }
    return "alert-danger"
  }

  isLessThanXDays_Object(healthObjectListResDto: HealthObjectListResDto, days: number = 2){
    return this.isLessThanXDays(healthObjectListResDto.lastDateCreated, days)
  }

}

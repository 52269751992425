export class UniswapInstance {
    uniswap_instance_id?: number
    uuid?: string
    
    type?: number
    name?: string
    website?: string
    blockchain_name?: string
    blockchain_explorer?: string
    factory_address: string = ""
    router_address: string = ""
    wmatic_address: string = ""
    usdc_address?: string
    weth_address?: string
    usdt_address?: string

    record_status?: number
    created_at?: string
    updated_at?: string
}
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Location } from '@angular/common'
import { Subject, interval, Subscription } from 'rxjs'
import { DataTableDirective } from 'angular-datatables'
import { BotService } from 'src/app/services/bot.service'
import { UserBotLog } from 'src/app/models/userbotlog'
import { UniswapBotDto } from 'src/app/dtos/uniswapbotdto'
import { UniswapBotTxService } from 'src/app/services/uniswapbottx.service'
import { UniswapBotTx } from 'src/app/models/uniswapbottx'
import { UniswapBotService } from 'src/app/services/uniswapbot.service'
import { GamePlayer } from 'src/app/models/gameplayer'
import { GamePlayerService } from 'src/app/services/gameplayer.service'
import { ToastrService } from 'ngx-toastr'

@Component({
  selector: 'app-game-player-list-txs',
  templateUrl: './game-player-list.component.html',
  styleUrls: ['./game-player-list.component.css']
})
export class GamePlayerListComponent implements OnInit, OnDestroy {

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective
  dtOptions: DataTables.Settings = {}
  dtTrigger: Subject<any> = new Subject<any>()
  
  autoRefreshInterval: Subscription = new Subscription()

  uniswapBot?: UniswapBotDto
  gamePlayers?: GamePlayer[]
  blockchainExplorerUrl?: string

  constructor(
    private route: ActivatedRoute,
    private gamePlayerService: GamePlayerService,
    private uniswapBotService: UniswapBotService,
    private location: Location,
    private toastr: ToastrService
  ) {}
  
  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      order: []
    }

    this.getUniswapBotDetails()
  }

  ngAfterViewInit(): void {
    this.getGamePlayers()
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe()
    this.autoRefreshInterval.unsubscribe()
  }

  getId() {
    return Number(this.route.snapshot.paramMap.get('id'))
  }
  
  getGamePlayers(): void {
    const id = this.getId()
    this.gamePlayerService.getGamePlayers(id).subscribe(response => {
      this.gamePlayers = response
      this.dtTrigger.next()
    })
  }

  // TODO: Move to common class...
  getUniswapBotDetails(): void {
    const id = this.getId()
    this.uniswapBotService.getBot(id).subscribe(response => {
      this.uniswapBot = response
      this.blockchainExplorerUrl = response.uniswap_instance.blockchain_explorer
    })
  }

  generateGamePlayers(units: number): void {
    const id = this.getId()
    this.toastr.info(`Adding ${units} players to the game`)
    var that = this
    alert(`This might take a while. Do not refresh!`)
    this.gamePlayerService.generateGamePlayers(id, units).subscribe(response => {
      // alert(response)
      that.toastr.success(`${response.length} added`)
      that.refresh()
    })
  }

  buyOnce(uniswap_bot_id: number) {
    this.toastr.info('Buying Once')
    this.uniswapBotService.uniswapBotStart(uniswap_bot_id, 1, 1).subscribe(response => {
      this.toastr.success(`${uniswap_bot_id}`, `Buy transaction sent`)
    })
  }

  sellOnce(uniswap_bot_id: number) {
    this.toastr.info('Selling Once')
    this.uniswapBotService.uniswapBotStart(uniswap_bot_id, 2, 1).subscribe(response => {
      this.toastr.success(`${uniswap_bot_id}`, `Sell transaction sent`)
    })
  }

  syncBalances(force: number = 0, threshold: number = 1440): void {
    const id = this.getId()
    // const force = 0 // 1: force, 0: don't
    // const threshold = 60*24 // (minutes), null: use server default
    var that = this

    this.toastr.info(`Syncing balances`)
    alert(`This might take a while. Do not refresh!`)
    this.uniswapBotService.syncBalances(id, force, threshold).subscribe(response => {
      // alert(response)
      that.toastr.success(`Syncing...`)
      that.refresh()
    })
  }

  substring_Six(input: string) {
    if (input && input.length > 7) {
      return input.substring(0,6)
    }
    return ""
  }

  goBack(): void {
    this.location.back()
  }

  refresh(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy()
      // Call the dtTrigger to rerender again
      this.getGamePlayers()
    })
    // window.location.reload()
  }

  toggleAutoRefresh(seconds: number) {
    this.autoRefreshInterval.unsubscribe()
    if (!isNaN(seconds) && seconds > 0) {
      this.enableAutoRefresh(seconds)
    }
  }

  enableAutoRefresh(seconds: number) {
    if (this.uniswapBot && this.uniswapBot.is_running) {
      this.autoRefreshInterval = interval(seconds*1000).subscribe(x => {
        this.refresh()
      })
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { BotService } from 'src/app/services/bot.service';

@Component({
  selector: 'app-portfolio-list',
  templateUrl: './portfolio-list.component.html',
  styleUrls: ['./portfolio-list.component.css']
})
export class PortfoliosComponent implements OnInit {

  portfolios: Portfolio[] = []

  constructor(
    private portfolioService: PortfolioService,
    private authenticationService: AuthenticationService,
    private botService: BotService
  ){}

  ngOnInit() {
    this.getPortfolios()
  }

  getPortfolios(): void {
    const currentUser = this.authenticationService.currentUserValue
    if (currentUser) {
      this.portfolioService.getPortfolios().subscribe(portfolios => {
        if (portfolios && portfolios.length > 0) {
          this.portfolios = portfolios
        }
      })
    }
  }
}

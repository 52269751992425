import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserBotDto } from 'src/app/dtos/userbotdto';
import { BotService } from 'src/app/services/bot.service';

@Component({
  selector: 'app-bot-actions',
  templateUrl: './bot-actions.component.html',
  styleUrls: ['./bot-actions.component.css']
})
export class BotActionsComponent implements OnInit {

  @Input() userBot: UserBotDto

  constructor(
    private botService: BotService,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
  }

  userBotStart(id: number): void {
    this.toastr.info('Starting bot')
    var that = this
    this.botService.userBotStart(id).subscribe(response => {
      this.toastr.success('Bot started')
      // this.router.navigate([`/bot/logs/${id}`])
      window.location.reload()
    })
  }

  userBotStop(id: number): void {
    this.toastr.info('Stopping bot')
    var that = this
    this.botService.userBotStop(id).subscribe(response => {
      this.toastr.success('Bot stopped')
      // this.router.navigate([`/bot/logs/${id}`])
      window.location.reload()
    })
  }

  userBotSignals(userBot: UserBotDto) {
    this.router.navigate([`/bot/signals/${userBot.id}`])
  }

  userBotDetails(userBot: UserBotDto) {
    this.router.navigate([`/bot/details/${userBot.id}`])
  }

  userBotLogs(userBot: UserBotDto) {
    this.router.navigate([`/bot/logs/${userBot.id}`])
  }

  userBotDuplicate(userBot: UserBotDto) {
    this.router.navigate([`/bot/add/${userBot.id}`])
  }

}

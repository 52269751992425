import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-uniswap-bot-detail',
  templateUrl: './uniswap-bot-detail.component.html',
  styleUrls: ['./uniswap-bot-detail.component.css']
})
export class UniswapBotDetailComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

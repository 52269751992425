import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { fromEventPattern, Observable, of } from 'rxjs'
import { MessageService } from './message.service'
import { catchError, tap } from 'rxjs/operators'
import { AuthenticationService } from './authentication.service'
import { environment } from '../../environments/environment'
import { AuthenticatedUser } from '../models/authentication'

@Injectable({
  providedIn: 'root'
})
export class SymbolService {

  headers: HttpHeaders
  currentUser: AuthenticatedUser

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private authenticationService: AuthenticationService
  ){
    this.headers = new HttpHeaders()
    this.authenticationService.currentUser.subscribe((user: AuthenticatedUser) => {
      this.currentUser = user

      if (user && user.token) {
        this.headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('Api-Key', `${environment.apiKey}`)
        .set('Authorization', `Bearer ${this.currentUser.token}`)
      }
    })
  }

  // Get Symbol List
  getSymbols(): Observable<MASymbol[]> {
    const url = `${environment.apiUrl}/symbol/`
    return this.http.get<MASymbol[]>(url, { headers: this.headers })
      .pipe(
        // tap((tickers: MASymbol[]) => this.log(`getSymbols()`)),
        catchError(this.handleError<MASymbol[]>('getSymbols()'))
      )
  }

  log(message: string) {
    this.messageService.add(`botService: ${message}`)
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
   private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error) // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`)

      // Let the app keep running by returning an empty result.
      return of(result as T)
    }
  }
}

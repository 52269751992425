import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { MessageService } from './message.service';
import { environment } from '../../environments/environment';
import { AuthenticationService } from './authentication.service';
import { AuthenticatedUser } from '../models/authentication';

@Injectable({
  providedIn: 'root'
})
export class TickersService {

  headers: HttpHeaders
  currentUser: AuthenticatedUser

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private authenticationService: AuthenticationService
  ){
    this.headers = new HttpHeaders()
    this.authenticationService.currentUser.subscribe((user: AuthenticatedUser) => {
      this.currentUser = user

      if (user && user.token) {
        this.headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('Api-Key', `${environment.apiKey}`)
        .set('Authorization', `Bearer ${this.currentUser.token}`)
      }
    })
  }

  // Get One Symbol
  getTickerPrice(symbol: string): Observable<PortfolioSymbolDto> {
    const url = `${environment.apiUrl}/ticker?symbols=${symbol}`
    return this.http.get<PortfolioSymbolDto>(url, { headers: this.headers })
      .pipe(
        // tap((tickers: PortfolioSymbolDto) => this.log(`getTickerPrice(${symbol})`)),
        catchError(this.handleError<PortfolioSymbolDto>('getTickerPrice()'))
      );
  }

  // Get One Symbol (slow Binance proxy)
  getSymbolPrice_proxy(symbol: string): Observable<PortfolioSymbolDto> {
    const url = `${environment.apiUrl}/ticker/price/${symbol}`
    return this.http.get<PortfolioSymbolDto>(url, { headers: this.headers })
      .pipe(
        // tap((tickers: PortfolioSymbolDto) => this.log(`getSymbolPrice_proxy(${symbol})`)),
        catchError(this.handleError<PortfolioSymbolDto>('getSymbolPrice_proxy()'))
      );
  }

  // // All Symbols
  // getAllTickersPrice(): Observable<PortfolioSymbol[]> {
  //   const url = `${environment.apiUrl}/ticker`
  //   return this.http.get<PortfolioSymbol[]>(url, { headers: this.headers })
  //     .pipe()
  // }

  // // Multiple Symbols
  // getTickersData(tickersArr: PortfolioSymbol[]): Observable<PortfolioSymbol[]> {
  //   var symbolsString = ''
  //   for (let ticker of tickersArr){
  //     symbolsString = symbolsString + `symbols[]=${ticker.symbol}/USDT&`
  //   }
  //   const url = `${environment.apiUrl}/ticker?${symbolsString}`
  //   return this.http.get<PortfolioSymbol[]>(url, { headers: this.headers })
  //     .pipe()
  // }

  log(message: string) {
    this.messageService.add(`SymbolService: ${message}`);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
   private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}

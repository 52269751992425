export class EthereumTx {
    uuid?: string

    to_address?: string
    from_address?: string
    contract_address?: string
    amount?: number
    tx_hash?: string
    ethereum_tx_type?: number

    record_status?: number
    created_at?: string
    updated_at?: string

    error?: any
}


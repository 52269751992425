import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot } from '@angular/router'
import { BehaviorSubject, Observable, throwError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { AuthenticatedUser, LoginResDto, LoginReqDto, SignupReqDto } from '../models/authentication'

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  headers: HttpHeaders
  private currentUserSubject: BehaviorSubject<AuthenticatedUser>
  public currentUser: Observable<AuthenticatedUser>

  constructor(
    private http: HttpClient
  ) {
    this.currentUserSubject = new BehaviorSubject<AuthenticatedUser>(JSON.parse(localStorage.getItem('currentUser') || '{}'))
    this.currentUser = this.currentUserSubject.asObservable()

    this.headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Accept', 'application/json')
    .set('Api-Key', `${environment.apiKey}`)
  }

  public get currentUserValue(): AuthenticatedUser {
    // when to use this and when to subscribe to currentUser?
    // console.log(`subscribe ${this.currentUser.token}`)
    // console.log(`currentUserValue ${this.authenticationService.currentUserValue.token}`)

    const currentUserValue = this.currentUserSubject.getValue() //this.currentUserSubject.value
    if (currentUserValue && currentUserValue.token && !this.isTokenExpired(currentUserValue.token)) {
      return currentUserValue
    } else {
      this.logout() // in case there is a token but it's expired
      return null
    }
  }

  isTokenExpired(token: string) {
    const expiryDateTimestamp = (JSON.parse(atob(token.split('.')[1]))).exp
    const nowTimestamp = (Math.floor((new Date).getTime() / 1000))
    
    return nowTimestamp >= expiryDateTimestamp
  }

  updateCurrentUser(user: AuthenticatedUser){
    localStorage.setItem('currentUser', JSON.stringify(user))
    this.currentUserSubject.next(user)
  }

  logout() {
    localStorage.removeItem('currentUser')
    // localStorage.clear()
    this.currentUserSubject.next(null)
  }

  login(loginUser: LoginReqDto): any {
    return this.http.post<LoginResDto>(environment.apiUrl + '/authentication/login', loginUser, { headers: this.headers })
      .pipe(map(response => {
        if (response && response.result && response.user.token) {
          const authenticatedUser = new AuthenticatedUser()
          authenticatedUser.token = response.user.token
          authenticatedUser.default_portfolio_id = response.user.default_portfolio_id || 0

          this.updateCurrentUser(authenticatedUser)
        }
        return response
      }))
  }

  signup(newUser: SignupReqDto): any {
    return this.http.post<LoginResDto>(`${environment.apiUrl}/authentication/signup`, newUser, { headers: this.headers })
      .pipe(
        map(response => {
          if (response && response.result && response.user.token) {
            const authenticatedUser = new AuthenticatedUser()
            authenticatedUser.token = response.user.token
            authenticatedUser.default_portfolio_id = response.user.default_portfolio_id || 0
  
            this.updateCurrentUser(authenticatedUser)
          }
          return response
        }),
        catchError(this.handleError)
        
        // tap((newPortfolio: UserBotAddResDto) => this.log(`PortfolioAdd w/ id=${newPortfolio.id}`)),
      )
  }

  handleError(error: HttpErrorResponse) {
    return throwError(error);
}
  
}

import { Component, OnInit } from '@angular/core'
import * as LogRocket from 'logrocket'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  constructor() { }

  ngOnInit() {
    LogRocket.init('w6krch/dm-crypto-tracker')
  }
}

export enum UniswapEventEnum {
    none,
    Buy = 1, //swapExactETHForTokens = 1, // (buy)
    Sell = 2, //swapExactTokensForETH = 2, // (sell)
    Swap = 3, //swapExactTokensForTokens = 3, // (swap)
    Approve = 4
}

export namespace UniswapEventEnum {
    export function toString(key: UniswapEventEnum): string {
        return UniswapEventEnum[key];
    }

    export function fromString(key: string): UniswapEventEnum {
        return (UniswapEventEnum as any)[key.toLowerCase()];
    }

    export function toStringFromArrayOfEnums(array: number[]){
        var names: string[] = []
        for (let item of array) {
            names.push(UniswapEventEnum.toString(item))
        }
        return names
    }
}
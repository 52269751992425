import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PortfolioReqDto } from 'src/app/dtos/portfolioreqdto';
import { PortfolioService } from 'src/app/services/portfolio.service';

@Component({
  selector: 'app-portfolio-add',
  templateUrl: './portfolio-add.component.html',
  styleUrls: ['./portfolio-add.component.css']
})
export class PortfolioAddComponent implements OnInit {

  constructor(
    private portfolioService: PortfolioService,
    private toastr: ToastrService,
    private router: Router,
  ) { }

  ngOnInit() {
  }

  addPortfolio(portfolioName: string, portfolioApiKey: string, portfolioApiSecret: string) {
    if (!portfolioName) { 
      this.toastr.error('Invalid name')
      return 
    } else {
      this.toastr.info(`Adding ${portfolioName}`)
    }

    var portfolioReqDto: PortfolioReqDto = {
      name: portfolioName.trim(),
      apiKey: portfolioApiKey,
      apiSecret: portfolioApiSecret
    }

    var that = this
    this.portfolioService.addPortfolio(portfolioReqDto)
      .subscribe(portfolioResDto => {
        that.toastr.success(`${portfolioResDto.portfolioDto.name}`)
        this.router.navigate(['/dashboard'])
      });
  }
}

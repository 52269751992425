import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable, of } from 'rxjs'
import { MessageService } from './message.service'
import { catchError, tap } from 'rxjs/operators'
import { environment } from '../../environments/environment'
import { HealthSummaryResDto } from '../models/dobleye/healthsummary'

@Injectable({
  providedIn: 'root'
})
export class DobleyeService {

  headers: HttpHeaders
  
  constructor(
      private http: HttpClient,
      private messageService: MessageService
    ) {
        this.headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        // .set('Api-Key', `${environment.apiKey}`)
  }

  // Get Health Summary
  getHealthSummary(): Observable<HealthSummaryResDto> {
    const url = `${environment.dobleyeApiUrl}/health/summary`
    return this.http.get<HealthSummaryResDto>(url, { headers: this.headers })
      .pipe(
        catchError(this.handleError<HealthSummaryResDto>('getHealthSummary()'))
      )
  }

  log(message: string) {
    this.messageService.add(`dobleyeService: ${message}`)
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
   private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error) // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`)

      // Let the app keep running by returning an empty result.
      return of(result as T)
    }
  }
}

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Location } from '@angular/common'
import { Subject, interval, Subscription } from 'rxjs'
import { DataTableDirective } from 'angular-datatables'
import { BotService } from 'src/app/services/bot.service'
import { UserBotLog } from 'src/app/models/userbotlog'
import { UniswapBotDto } from 'src/app/dtos/uniswapbotdto'
import { UniswapBotTxService } from 'src/app/services/uniswapbottx.service'
import { UniswapBotTx } from 'src/app/models/uniswapbottx'
import { UniswapBotService } from 'src/app/services/uniswapbot.service'

@Component({
  selector: 'app-uniswap-bot-txs',
  templateUrl: './uniswap-bot-txs.component.html',
  styleUrls: ['./uniswap-bot-txs.component.css']
})
export class UniswapBotTxComponent implements OnInit, OnDestroy {

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective
  dtOptions: DataTables.Settings = {}
  dtTrigger: Subject<any> = new Subject<any>()
  
  autoRefreshInterval: Subscription = new Subscription()

  uniswapBot?: UniswapBotDto
  uniswapBotTxs?: UniswapBotTx[]

  constructor(
    private route: ActivatedRoute,
    private uniswapBotTxService: UniswapBotTxService,
    private uniswapBotService: UniswapBotService,
    private location: Location
  ) {}
  

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      order: []
    }

    this.getUniswapBotDetails()
  }

  ngAfterViewInit(): void {
    this.getUniswapBotTxs()
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe()
    this.autoRefreshInterval.unsubscribe()
  }

  getId() {
    return Number(this.route.snapshot.paramMap.get('id'))
  }
  
  getUniswapBotTxs(): void {
    const id = this.getId()
    this.uniswapBotTxService.getUniswapBotTxs(id).subscribe(response => {
      this.uniswapBotTxs = response
      this.dtTrigger.next()
    })
  }

  getUniswapBotDetails(): void {
    const id = this.getId()
    this.uniswapBotService.getBot(id).subscribe(response => {
      this.uniswapBot = response
    })
  }

  substring_Six(input: string) {
    if (input && input.length > 7) {
      return input.substring(0,6)
    }
    return ""
  }

  goBack(): void {
    this.location.back()
  }

  refresh(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy()
      // Call the dtTrigger to rerender again
      this.getUniswapBotTxs()
    })
    // window.location.reload()
  }

  toggleAutoRefresh(seconds: number) {
    this.autoRefreshInterval.unsubscribe()
    if (!isNaN(seconds) && seconds > 0) {
      this.enableAutoRefresh(seconds)
    }
  }

  enableAutoRefresh(seconds: number) {
    if (this.uniswapBot && this.uniswapBot.is_running) {
      this.autoRefreshInterval = interval(seconds*1000).subscribe(x => {
        this.refresh()
      })
    }
  }
}

export enum TradeSideEnum {
    none,
    buy = 1,
    sell = 2
}

export namespace TradeSideEnum {
    export function toString(key: TradeSideEnum): string {
        return TradeSideEnum[key];
    }

    export function fromString(key: string): TradeSideEnum {
        return (TradeSideEnum as any)[key.toLowerCase()];
    }
}
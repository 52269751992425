import { UserBotNotificationProviderDto } from "./userbotnotificationproviderdto"

export class UserBotDto {
    id?: number
    uuid?: string
    user_id?: number
    user_exchange_id?: number
    name?: string
    base_symbol?: string
    exchange?: number
    futures?: number
    min_volume?: number
    is_running?: number
    last_start?: number
    last_stop?: number
    forced_stop?: number
    record_status?: number
    created_at?: string
    updated_at?: string

    // Common DTO
    symbols?: any[] = []
    conditions?: any[] = []

    // Request DTO
    notification_providers_enum?: number[] = []
    user_exchange?: UserExchangeDto
    futures_bool?: boolean
    
    // Response DTO
    exchange_str?: string = ""
    notification_providers_name?: string[] = []
    notification_providers?: UserBotNotificationProviderDto[] = []
}

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Location } from '@angular/common'
import { Subject, interval, Subscription } from 'rxjs'
import { DataTableDirective } from 'angular-datatables'
import { BotService } from 'src/app/services/bot.service'
import { BotLogService } from 'src/app/services/botlog.service'
import { UserBotLog } from 'src/app/models/userbotlog'
import { UserBotDto } from 'src/app/dtos/userbotdto'

@Component({
  selector: 'app-bot-logs',
  templateUrl: './bot-logs.component.html',
  styleUrls: ['./bot-logs.component.css']
})
export class BotLogsComponent implements OnInit, OnDestroy {

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective
  dtOptions: DataTables.Settings = {}
  dtTrigger: Subject<any> = new Subject<any>()
  
  autoRefreshInterval: Subscription = new Subscription()

  userBot?: UserBotDto
  userBotLogs?: UserBotLog[]

  constructor(
    private route: ActivatedRoute,
    private botLogService: BotLogService,
    private botService: BotService,
    private location: Location
  ) {}
  

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      order: []
    }

    this.getUserBotDetails()
  }

  ngAfterViewInit(): void {
    this.getUserBotLogs()
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe()
    this.autoRefreshInterval.unsubscribe()
  }

  getId() {
    return Number(this.route.snapshot.paramMap.get('id'))
  }
  
  getUserBotLogs(): void {
    const id = this.getId()
    this.botLogService.getBotLogs(id).subscribe(response => {
      this.userBotLogs = response
      this.dtTrigger.next()
    })
  }

  getUserBotDetails(): void {
    const id = this.getId()
    this.botService.getBot(id).subscribe(response => {
      this.userBot = response
    })
  }

  goBack(): void {
    this.location.back()
  }

  refresh(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy()
      // Call the dtTrigger to rerender again
      this.getUserBotLogs()
    })
    // window.location.reload()
  }

  toggleAutoRefresh(seconds: number) {
    this.autoRefreshInterval.unsubscribe()
    if (!isNaN(seconds) && seconds > 0) {
      this.enableAutoRefresh(seconds)
    }
  }

  enableAutoRefresh(seconds: number) {
    if (this.userBot && this.userBot.is_running) {
      this.autoRefreshInterval = interval(seconds*1000).subscribe(x => {
        this.refresh()
      })
    }
  }
}

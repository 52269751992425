import { Component, Input, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Location } from '@angular/common'

import { PortfolioService } from '../../services/portfolio.service'

@Component({
  selector: 'app-portfolio-symbol-detail',
  templateUrl: './portfolio-symbol-detail.component.html',
  styleUrls: ['./portfolio-symbol-detail.component.css']
})
export class PortfolioSymbolDetailComponent implements OnInit {

  @Input() symbol?: PortfolioSymbolDto

  constructor(
    private route: ActivatedRoute,
    private portfolioService: PortfolioService,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.getSymbol()
  }
  
  getSymbol(): void {
    const id = Number(this.route.snapshot.paramMap.get('id'))
    this.portfolioService.getPortfolioSymbol(id)
      .subscribe(symbol => this.symbol = symbol)
  }

  goBack(): void {
    this.location.back()
  }

}

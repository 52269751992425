import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { fromEventPattern, Observable, of } from 'rxjs'
import { MessageService } from './message.service'
import { catchError, tap } from 'rxjs/operators'
import { AuthenticationService } from './authentication.service'
import { environment } from '../../environments/environment'
import { AuthenticatedUser } from '../models/authentication'
import { UniswapInstance } from '../models/uniswapinstance'

@Injectable({
  providedIn: 'root'
})
export class UniswapInstanceService {

  headers: HttpHeaders
  currentUser: AuthenticatedUser

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private authenticationService: AuthenticationService
  ){
    this.headers = new HttpHeaders()
    this.authenticationService.currentUser.subscribe((user: AuthenticatedUser) => {
      this.currentUser = user

      if (user && user.token) {
        this.headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('Api-Key', `${environment.apiKey}`)
        .set('Authorization', `Bearer ${this.currentUser.token}`)
      }
    })
  }

  // Get Uniswap Instance List
  getInstances(): Observable<UniswapInstance[]> {
    const url = `${environment.apiUrl}/uniswapinstance`
    return this.http.get<UniswapInstance[]>(url, { headers: this.headers })
      .pipe(
        // tap((tickers: UniswapInstance[]) => this.log(`getInstances()`)),
        catchError(this.handleError<UniswapInstance[]>('getInstances()'))
      )
  }

  // Get Uniswap Instance Details
  getInstance(id: number): Observable<UniswapInstance> {
    const url = `${environment.apiUrl}/uniswapinstance/details/${id}`
    return this.http.get<UniswapInstance>(url, { headers: this.headers })
      .pipe(
        // tap((tickers: UniswapInstance) => this.log(`getInstance()`)),
        catchError(this.handleError<UniswapInstance>('getInstance()'))
      )
  }

  // // Add Uniswap Instance
  addUniswapInstance(uniswapInstance: UniswapInstance): Observable<UniswapInstance> {
    return this.http.post<UniswapInstance>(`${environment.apiUrl}/uniswapinstance`, uniswapInstance, { headers: this.headers })
      .pipe(
        // tap((newPortfolio: UniswapInstance) => this.log(`PortfolioAdd w/ id=${newPortfolio.id}`)),
        catchError(this.handleError<UniswapInstance>('addUniswapInstance'))
      )
  }

  // Delete Instance
  uniswapInstanceDelete(id): Observable<UniswapInstance> {
    const url = `${environment.apiUrl}/uniswapinstance/${id}`
    return this.http.delete<UniswapInstance>(url, { headers: this.headers })
      .pipe(
        // tap((tickers: UniswapInstance) => this.log(`uniswapInstanceDelete()`)),
        catchError(this.handleError<UniswapInstance>('uniswapInstanceDelete()'))
      )
  }

  log(message: string) {
    this.messageService.add(`UniswapbotService: ${message}`)
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
   private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error) // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`)

      // Let the app keep running by returning an empty result.
      return of(result as T)
    }
  }
}

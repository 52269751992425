import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PortfolioHoldingsComponent } from './components/portfolio/portfolio-holdings/portfolio-holdings.component'
import { DashboardComponent } from './components/dashboard/dashboard.component'
import { PortfolioSymbolDetailComponent } from './components/portfolio-symbol-detail/portfolio-symbol-detail.component'
import { LoginComponent } from './components/account/login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { PortfolioAddComponent } from './components/portfolio/portfolio-add/portfolio-add.component';
import { BotListComponent } from './components/bot/bot-list/bot-list.component';
import { BotAddComponent } from './components/bot/bot-add/bot-add.component';
import { BotDetailComponent } from './components/bot/bot-detail/bot-detail.component';
import { BotSignalsComponent } from './components/bot/bot-signals/bot-signals.component';
import { BotLogsComponent } from './components/bot/bot-logs/bot-logs.component';
import { SignupComponent } from './components/account/signup/signup.component';
import { ProfileComponent } from './components/account/profile/profile.component';
import { DobleyeComponent } from './components/dobleye/dobleye.component';
import { UniswapBotListComponent } from './components/uniswap-bot/uniswap-bot-list/uniswap-bot-list.component';
import { UniswapBotDetailComponent } from './components/uniswap-bot/uniswap-bot-detail/uniswap-bot-detail.component';
import { UniswapBotTxComponent } from './components/uniswap-bot/uniswap-bot-txs/uniswap-bot-txs.component';
import { GamePlayerListComponent } from './components/uniswap-bot/game-player-list/game-player-list.component';
import { UniswapBotAddComponent } from './components/uniswap-bot/uniswap-bot-add/uniswap-bot-add.component';
import { TokenDetailComponent } from './components/uniswap-bot/token-detail/token-detail.component';
import { WithdrawComponent } from './components/funds/withdraw/withdraw.component';
// import { UniswapBotComponent } from './components/uniswap-bot/uniswap-bot.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'signup/:referral', component: SignupComponent },
  { path: 'profile', canActivate: [AuthGuard], component: ProfileComponent },
  { path: 'dashboard', canActivate: [AuthGuard], component: DashboardComponent },

  { path: 'portfolio/holdings/:id', canActivate: [AuthGuard], component: PortfolioHoldingsComponent },
  { path: 'portfolio/add', canActivate: [AuthGuard], component: PortfolioAddComponent },
  { path: 'portfolio/symbol/details/:id', canActivate: [AuthGuard], component: PortfolioSymbolDetailComponent },
  
  { path: 'bots', canActivate: [AuthGuard], component: BotListComponent },
  { path: 'bot/add', canActivate: [AuthGuard], component: BotAddComponent },
  { path: 'bot/add/:id', canActivate: [AuthGuard], component: BotAddComponent },
  { path: 'bot/details/:id', canActivate: [AuthGuard], component: BotDetailComponent },
  { path: 'bot/signals/:id', canActivate: [AuthGuard], component: BotSignalsComponent },
  { path: 'bot/logs/:id', canActivate: [AuthGuard], component: BotLogsComponent },

  { path: 'uniswapbots', canActivate: [AuthGuard], component: UniswapBotListComponent },
  { path: 'uniswapbot/add', canActivate: [AuthGuard], component: UniswapBotAddComponent },
  { path: 'uniswapbot/details/:id', canActivate: [AuthGuard], component: UniswapBotDetailComponent },
  { path: 'uniswapbottx/bot/:id', canActivate: [AuthGuard], component: UniswapBotTxComponent },
  { path: 'gameplayer/bot/:id', canActivate: [AuthGuard], component: GamePlayerListComponent },
  { path: 'gameplayer/withdraw/:from_address', canActivate: [AuthGuard], component: WithdrawComponent },
  { path: 'token-detail/:token', canActivate: [AuthGuard], component: TokenDetailComponent },

  { path: 'dbl', component: DobleyeComponent },
  
  { path: '**', component: LoginComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UniswapBotDto } from 'src/app/dtos/uniswapbotdto';
import { UniswapBotService } from 'src/app/services/uniswapbot.service';

@Component({
  selector: 'app-uniswap-bot-actions',
  templateUrl: './uniswap-bot-actions.component.html',
  styleUrls: ['./uniswap-bot-actions.component.css']
})
export class UniswapBotActionsComponent implements OnInit {

  @Input() uniswapBot: UniswapBotDto

  constructor(
    private uniswapBotService: UniswapBotService,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
  }

  uniswapBotStart(uniswapBot: UniswapBotDto) {
    this.toastr.info('Starting bot')
    var that = this
    this.uniswapBotService.uniswapBotStart(uniswapBot.uniswap_bot_id).subscribe(response => {
      this.toastr.success(`${uniswapBot.uniswap_bot_id}`, `Bot started`)
      window.location.reload()
    })
  }

  uniswapBotStop(uniswapBot: UniswapBotDto) {
    this.toastr.info('Stopping bot')
    var that = this
    this.uniswapBotService.uniswapBotStop(uniswapBot.uniswap_bot_id).subscribe(response => {
      this.toastr.success(`${uniswapBot.uniswap_bot_id}`, `Bot stopped`)
      window.location.reload()
    })
  }

  uniswapBotDetails(uniswapBot: UniswapBotDto) {
    this.router.navigate([`/uniswapbot/details/${uniswapBot.uniswap_bot_id}`])
  }

  uniswapBotTxs(uniswapBot: UniswapBotDto) {
    this.router.navigate([`/uniswapbottx/bot/${uniswapBot.uniswap_bot_id}`])
  }

  gamePlayers(uniswapBot: UniswapBotDto) {
    this.router.navigate([`/gameplayer/bot/${uniswapBot.uniswap_bot_id}`])
  }

  uniswapBotDuplicate(uniswapBot: UniswapBotDto) {
    this.router.navigate([`/uniswapbot/add/${uniswapBot.uniswap_bot_id}`])
  }

}

// Common / Helper / Utils

export class Utils {
    public static roundNumber(raw: number) {
        return (Math.round(raw * 100) / 100)
    }

    public static currencyNumber(raw: number) {
        // Create our number formatter.
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        
            // These options are needed to round to whole numbers if that's what you want.
            minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        })
        
        return formatter.format(raw) /* $2,500.00 */
    }

    public static addMinutes(date: any, minutes: any) {
        return new Date(date.getTime() + minutes*60000)
    }

    public static nowTimestamp() {
        return (Math.floor((new Date).getTime() / 1000))
    }

    public static timestampToDate(ts: number) {
        return new Date(ts*1000).toLocaleString("en-AU")
    }
}
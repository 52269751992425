import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { Utils } from '../../utils'
import { AuthenticationService } from '../../services/authentication.service'
import { TickersService } from '../../services/tickers.service'
import * as LogRocket from 'logrocket'
import { Globals } from 'src/app/globals'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  title = environment.title
  btcusdt: string

  isAuthenticated: boolean
  subscription: Subscription
  
  constructor(
    private authenticationService: AuthenticationService,
    private tickersService: TickersService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.subscription = this.authenticationService.currentUser.subscribe(user => {
      if (user && user.token) {
        this.isAuthenticated = true
        // this.getBtcUsdtPrice()

        LogRocket.identify(`${user.token}`, {
          default_portfolio_id: user.default_portfolio_id,
        });
      }
      else {
        this.isAuthenticated = false
      }
    })
  }

  getBtcUsdtPrice() {
    const symbol = "BTC"
    const that = this
    this.tickersService.getTickerPrice(symbol)
      .subscribe(ticker => {
        if (!ticker || !ticker.symbol || ticker.symbol.length < 2){
          console.error(`Invalid symbol ${ticker}`)
          return
        }
        
        if (ticker.price || !isNaN(ticker.price)) {
          const assetPrice = ticker.price
          const changePct = ticker.changePct
          that.btcusdt = `BTC/USDT: ${Utils.currencyNumber(assetPrice)} (${changePct} %)`
        }
      },
      err => {
        console.error(err);
      }
      )
  }

  logout() {
    this.isAuthenticated = false
    this.authenticationService.logout()
    this.router.navigate(['/login'])
  }

  ngOnDestroy() {
    // this.subscription.unsubscribe()
  }

}

import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { MatSort } from '@angular/material/sort'
import { MatTable, MatTableDataSource } from '@angular/material/table'
import { BotService } from 'src/app/services/bot.service'
import { AuthenticationService } from 'src/app/services/authentication.service'
import { Router } from '@angular/router'
import { UserBotDto } from 'src/app/dtos/userbotdto'

@Component({
  selector: 'app-bot-list',
  templateUrl: './bot-list.component.html',
  styleUrls: ['./bot-list.component.css']
})

export class BotListComponent implements OnInit, AfterViewInit, OnDestroy {

  userBots: UserBotDto[] = []
  dataSource = new MatTableDataSource(this.userBots)

  displayedColumns: string[] = ['action', 'name', 'isRunning', 'quoteSymbol', 'baseSymbols']

  constructor(
    private authenticationService: AuthenticationService,
    private botService: BotService,
    private router: Router
  ){}

  ngOnDestroy(): void {
  }
  
  
  ngOnInit() {
    this.getBots()
  }
  
  @ViewChild(MatTable, {static:true}) userBotsTable: MatTable<any>
  @ViewChild(MatSort, {static: false}) sort: MatSort

  ngAfterViewInit() {
    this.dataSource.sort = this.sort
  }
  
  getBots(): void {
    const currentUser = this.authenticationService.currentUserValue
    if (currentUser) {
      var that = this
      this.botService.getBots().subscribe(result => {
        if (result && result.length > 0) {
          that.dataSource.data = result
        }
      })
    }
  }
}

import { Component, NgZone, OnInit } from '@angular/core';
import { async } from '@angular/core/testing';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as LogRocket from 'logrocket';
import { ToastrService } from 'ngx-toastr';
import { LoginReqDto, LoginResDto, SignupReqDto } from 'src/app/models/authentication';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  signupForm: FormGroup
  isAuthenticated: boolean
  accounts: any
  currentAccount: any
  submitted: boolean = false
  saving: boolean = false
  referral: string = ''

  get email() { return this.signupForm.get('email') }
  get password() { return this.signupForm.get('password') }
  // get referral() { return this.signupForm.get('referral') }
  get name() { return this.signupForm.get('name') }

  constructor(
    private router: Router, 
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService, 
    private formBuilder: FormBuilder, 
    private zone: NgZone,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(user => {
      this.isAuthenticated = (user && user.token != null)
    })

    if (this.isAuthenticated) {
      this.router.navigate(['/dashboard'])
    }

    const referralParam = this.route.snapshot.paramMap.get('referral')
    if (referralParam && referralParam.length == 36) {
      this.referral = referralParam
      this.setupForm()
    } else {
      this.router.navigate(['/login'])
    }
  }

  setupForm() {
    this.signupForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.required],
      name: ['', Validators.required]
    })
  }

  async signup() {
    this.submitted = true
    if (this.signupForm.valid) {
      this.saving = true

      let newUser = new SignupReqDto()
      newUser.email = this.email.value.trim()
      newUser.password = this.password.value
      newUser.referral = this.referral
      newUser.name = this.name.value

      var that = this

      this.authenticationService.signup(newUser)
        .subscribe(response => {
          that.saving = false
          if (response && response.result && response.user && response.user.token) {
            that.isAuthenticated = response.result

            LogRocket.identify(`${response.user.uuid}`, {
              email: newUser.email,
            });

            that.toastr.success('Signup successful')
            that.router.navigate(['/dashboard'])
          } else if (response && response.error) {
            that.toastr.error(response.error)
          } else {
            that.toastr.error(`Signup failed`)
          }
        }, (error: any) => {
          that.saving = false
          // that.toastr.error(`Signup failed ${JSON.stringify(error)}`)
          console.log(error)
          that.toastr.error(`Signup failed - ${error.error.error}`)

        })
    } 
  }
}
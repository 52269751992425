import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserBotDto } from 'src/app/dtos/userbotdto';
import { TradeSideEnum } from 'src/app/enums/tradesideenum';
import { UserBotCondition } from 'src/app/models/userbotcondition';
import { BotService } from 'src/app/services/bot.service';

@Component({
  selector: 'app-bot-detail',
  templateUrl: './bot-detail.component.html',
  styleUrls: ['./bot-detail.component.css']
})
export class BotDetailComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router, 
    private botService: BotService,
  ) {}

  userBot?: UserBotDto
  buyConditions?: UserBotCondition[]
  sellConditions?: UserBotCondition[]

  ngOnInit(): void {
    this.getUserBot()
  }
  
  getUserBot(): void {
    const id = Number(this.route.snapshot.paramMap.get('id'))
    this.botService.getBot(id).subscribe(response => {
      this.userBot = response
      this.getUserBotConditionsBuy()
      this.getUserBotConditionsSell()
    })
  }

  getUserBotConditionsBuy() {
    this.buyConditions = this.userBot.conditions
      .filter(condition => 
        condition.side == TradeSideEnum.buy
      )
  }

  getUserBotConditionsSell() {
    this.sellConditions = this.userBot.conditions
    .filter(condition => 
      condition.side == TradeSideEnum.sell
    )
  }

  userBotDelete(id: number): void {
    var that = this
    this.botService.userBotDelete(id).subscribe(response => {
      this.router.navigate(['/bots'])
    })
  }

}

import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { fromEventPattern, Observable, of } from 'rxjs'
import { MessageService } from './message.service'
import { catchError, tap } from 'rxjs/operators'
import { AuthenticationService } from './authentication.service'
import { environment } from '../../environments/environment'
import { AuthenticatedUser } from '../models/authentication'
import { UserBotAddResDto } from '../dtos/userbotaddresdto'
import { EthereumTx } from '../models/ethereumtx'

@Injectable({
  providedIn: 'root'
})
export class EthereumTxService {

  headers: HttpHeaders
  currentUser: AuthenticatedUser

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private authenticationService: AuthenticationService
  ){
    this.headers = new HttpHeaders()
    this.authenticationService.currentUser.subscribe((user: AuthenticatedUser) => {
      this.currentUser = user

      if (user && user.token) {
        this.headers = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('Api-Key', `${environment.apiKey}`)
        .set('Authorization', `Bearer ${this.currentUser.token}`)
      }
    })
  }

  // Submit Ethereum Tx
  submitEthereumTx(ethereumTx: EthereumTx): Observable<EthereumTx> {
    return this.http.post<EthereumTx>(`${environment.apiUrl}/gameplayer/transfer/ethereum/`, ethereumTx, { headers: this.headers })
      .pipe(
        // tap((newPortfolio: EthereumTx) => this.log(`PortfolioAdd w/ id=${newPortfolio.id}`)),
        catchError(this.handleError<EthereumTx>('submitEthereumTx'))
      )
  }

  log(message: string) {
    this.messageService.add(`UniswapbotService: ${message}`)
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
   private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error) // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`)

      // Let the app keep running by returning an empty result.
      return of(result as T)
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common'
import { UniswapBotDto } from 'src/app/dtos/uniswapbotdto';
import { UniswapBotService } from 'src/app/services/uniswapbot.service';
import { EthereumTx } from 'src/app/models/ethereumtx';
import { EthereumTxService } from 'src/app/services/ethereumtx.service';
import { GamePlayer } from 'src/app/models/gameplayer';
import { GamePlayerService } from 'src/app/services/gameplayer.service';

@Component({
  selector: 'app-withdraw',
  templateUrl: './withdraw.component.html',
  styleUrls: ['./withdraw.component.css']
})

export class WithdrawComponent implements OnInit {
  ethereumTx: EthereumTx
  gamePlayer: GamePlayer

  constructor(
    private ethereumTxService: EthereumTxService,
    private gamePlayerService: GamePlayerService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private location: Location
  ) { }

  ngOnInit() {
    this.getGamePlayerFromPublicKey(this.getPublicKey())
    this.loadDefaultEthereumTx()
  }

  getPublicKey() {
    return this.route.snapshot.paramMap.get('from_address')
  }

  loadDefaultEthereumTx(){
    // Ethereum Tx
    const defaultEthereumTx = new EthereumTx()
    defaultEthereumTx.to_address = "0x893d7db59b57d1c5a755876c7397f76b0de47715" //"0x73e3542f0Dbf3754b56b8122a59B4a692DAdC6f2"
    defaultEthereumTx.from_address = this.getPublicKey()
    defaultEthereumTx.contract_address = "" // "0x72b9f88e822cf08b031c2206612b025a82fb303c"
    defaultEthereumTx.amount = 1
    // defaultEthereumTx.ethereum_tx_type = 0

    this.ethereumTx = defaultEthereumTx
  }

  getGamePlayerFromPublicKey(publicKey: string): void {
    if (publicKey && publicKey.length == 42) {
      this.gamePlayerService.getGamePlayer(publicKey).subscribe(response => {
        this.gamePlayer = response
      })
    }
  }

  submitEthereumTx() {
    if (this.ethereumTx.contract_address && this.ethereumTx.contract_address.length != 42) {
      this.toastr.error('Invalid contract_address')
      return
    } else if (!this.ethereumTx.to_address) {
      this.toastr.error('Invalid to_address')
      return
    } else if (!this.ethereumTx.from_address) {
      this.toastr.error('Invalid from_address')
      return
    } else if (!this.ethereumTx.amount) {
      this.toastr.error('Invalid amount')
      return
    }

    var new_ethereumTx: EthereumTx = {
      to_address: this.ethereumTx.to_address,
      from_address: this.ethereumTx.from_address,
      contract_address: this.ethereumTx.contract_address,
      amount: this.ethereumTx.amount,
      tx_hash: this.ethereumTx.tx_hash,
      ethereum_tx_type: this.ethereumTx.ethereum_tx_type
    }

    this.toastr.info(`Withdrawing ${new_ethereumTx.amount}`)
    var that = this
    this.ethereumTxService.submitEthereumTx(this.ethereumTx)
      .subscribe(response => {
        if (response && response.tx_hash) {
          that.toastr.success(`${response.amount} sent to ${response.to_address}`)
          this.router.navigate([`/uniswapbots`])
        } else if (response && response.error) {
          that.toastr.error(response.error)
        } else {
          that.toastr.error(`Error submitting transaction`)
        }
      })
  }

  goBack(): void {
    this.location.back()
  }

}

import { GamePlayer } from "../models/gameplayer"
import { UniswapInstance } from "../models/uniswapinstance"

export class UniswapBotDto {
    uniswap_bot_id?: number
    uuid?: string
    
    user_id?: number
    uniswap_instance_id?: number
    name?: string
    blockchain_api?: string
    token_address?: string
    buy_amount: number = 0
    sell_amount?: number
    gas_limit?: number
    slippage_pc?: number
    deadline_mins: number  = 0
    min_delay?: number
    max_delay?: number
    buy_weight?: number
    is_running?: number
    last_start?: number
    last_stop?: number
    forced_stop?: number

    record_status?: number
    created_at?: string
    updated_at?: string

    uniswap_instance?: UniswapInstance = new UniswapInstance
    game_players?: GamePlayer[] = []

    total_balance_main_native?: number
    total_balance_main_other?: number
    last_balance_sync?: number

    error?: any
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BotService } from 'src/app/services/bot.service';
import { SymbolService } from 'src/app/services/symbol.service';
import { Location } from '@angular/common'
import { NotificationProviderService } from 'src/app/services/notificationprovider.service';
import { UserBotDto } from 'src/app/dtos/userbotdto';
import { UserBotAddResDto } from 'src/app/dtos/userbotaddresdto';
import { UserBotCondition } from 'src/app/models/userbotcondition';
import { TradeSideEnum } from 'src/app/enums/tradesideenum';
import { ExchangeEnum } from 'src/app/enums/exchangeenum';
import { BotConditionService } from 'src/app/services/botcondition.service';
import { BotCondition } from 'src/app/models/botcondition';
import { UserExchangeService } from 'src/app/services/userexchange.service';

@Component({
  selector: 'app-bot-add',
  templateUrl: './bot-add.component.html',
  styleUrls: ['./bot-add.component.css']
})
export class BotAddComponent implements OnInit {

  userBot: UserBotDto
  notificationProviders: NotificationProvider[]
  symbols: MASymbol[]
  userExchanges: UserExchangeDto[]
  
  botConditions: BotCondition[]
  buyConditions: UserBotCondition[] = []
  sellConditions: UserBotCondition[] = []

  constructor(
    private botService: BotService,
    private symbolService: SymbolService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private location: Location,
    private notificationProviderService: NotificationProviderService,
    private botConditionService: BotConditionService,
    private userExchangeService: UserExchangeService
  ) { }

  ngOnInit() {
    this.getConditionTypes()
    this.loadDefaultUserBot()
    this.getUserExchanges()
    this.getNotificationProviders()
    this.getUserBot()
    this.getSymbols()
  }

  loadDefaultUserBot(){
    // User Bot
    const defaultUserBot = new UserBotDto()
    defaultUserBot.name = ""
    defaultUserBot.base_symbol = "USDT"
    defaultUserBot.min_volume = 30000000
    this.userBot = defaultUserBot
  }

  loadDefaultBotConditions(){
    // this.addCondition(TradeSideEnum.buy, 1)
    // this.addCondition(TradeSideEnum.sell, 1)
  }

  addCondition(side: number, type: number){
    var newCondition: UserBotCondition = new UserBotCondition()
    newCondition.side = side
    newCondition.condition_type = type
    newCondition.bot_condition = this.botConditions[type-1]
    
    // TODO: Work out an efficient way to have one array but display separately on form
    if (side == TradeSideEnum.buy) {
      this.buyConditions.push(newCondition)
    } else if (side == TradeSideEnum.sell) {
      this.sellConditions.push(newCondition)
    } 
  }

  deleteCondition(condition: UserBotCondition){
    if (condition.side == TradeSideEnum.buy) {
      const buyIndex: number = this.buyConditions.indexOf(condition);
      if (buyIndex !== -1) {
          this.buyConditions.splice(buyIndex, 1);
      }   
    } else if (condition.side == TradeSideEnum.sell) {
      const sellIndex: number = this.sellConditions.indexOf(condition);
      if (sellIndex !== -1) {
          this.sellConditions.splice(sellIndex, 1);
      }   
    }
  }

  // To duplicate existing userBot
  getUserBot(): void {
    const id = Number(this.route.snapshot.paramMap.get('id'))
    if (id && !isNaN(id) && id > 0) {
      this.botService.getBot(id).subscribe(response => {
        this.userBot = response
        this.notificationsProviderSelectDefault()
      })
    }
  }

  getNotificationProviders(){
    this.notificationProviderService.getNotificationProviders().subscribe(response => {
      if (response && response.length > 0) {
        this.notificationProviders = response
        // this.notificationsProviderSelectDefault()
      }
    })
  }

  getConditionTypes(){
    this.botConditionService.getBotConditions().subscribe(response => {
      if (response && response.length > 0) {
        this.botConditions = response
        this.loadDefaultBotConditions()
      }
    })
  }

  getUserExchanges(){
    this.userExchangeService.getUserExchanges().subscribe(response => {
      if (response && response.length > 0) {
        this.userExchanges = response
        // this.notificationsProviderSelectDefault()
      }
    })
  }

  getSymbols(){
    this.symbolService.getSymbols().subscribe(response => {
      if (response && response.length > 0) {
        this.symbols = response
        this.userBotSymbolsSelectDefault()
      }
    })
  }

  get selectedNotificationProviders() {
    if (this.notificationProviders && this.notificationProviders.length > 0) {
      return this.notificationProviders
                .filter(notificationProvider => notificationProvider.checked)
                .map(notificationProvider => notificationProvider.id)
    }

    return []
  }

  notificationsProviderSelectDefault(){
    if (!this.userBot || !this.userBot.notification_providers_name || this.userBot.notification_providers_name.length < 1) return
    
    const that = this
    this.notificationProviders.forEach(notificationProvider => {
      if (that.userBot.notification_providers_name.includes(notificationProvider.name)) {
        notificationProvider.checked = true
      }
    });
  }

  get selectedSymbols() {
    return this.symbols
              .filter(symbol => symbol.checked)
              .map(symbol => symbol.id)
  }

  userBotSymbolsSelectAll(){
    this.symbols.forEach(symbol => {
      symbol.checked = true
    });
  }

  userBotSymbolsUnselectAll(){
    this.symbols.forEach(symbol => {
      symbol.checked = false
    });
  }

  userBotSymbolsSelectDefault(){
    if (!this.userBot || !this.userBot.symbols || this.userBot.symbols.length < 1) return
    
    const that = this
    this.symbols.forEach(symbol => {
      if (that.userBot.symbols.includes(symbol.name)) {
        symbol.checked = true
      }
    });
  }

  addUserBot() {
    if (!this.userBot.name) {
      this.toastr.error('Invalid Name')
      return
    } else if (!this.userBot.base_symbol) {
      this.toastr.error('Invalid Quote Symbol')
      return
    } else if (!this.userBot.min_volume) {
      this.toastr.error('Invalid Min Volume')
      return
    } else if (!this.selectedSymbols){
      this.toastr.error('Invalid Symbols')
      return
    } else if (this.selectedSymbols.length < 1){
      this.toastr.error('At least one symbol must be selected')
      return
    } else if (this.selectedSymbols.length > 2){
      this.toastr.error('Maximum 2 symbols')
      return
    }

    const conditions: UserBotCondition[] = this.buyConditions.concat(this.sellConditions)
    for (let condition of conditions) {
      if (!condition.interval && !condition.length && !condition.value1) {
        console.error(`Empty condition`)
        const index: number = conditions.indexOf(condition);
        if (index !== -1) {
          conditions.splice(index, 1);
        } 
      } else {
        condition.condition_type = condition.bot_condition.condition_type
      }
    }


    if (!conditions || conditions.length < 1){
      this.toastr.error('No start or stop conditions')
      return
    }

    var exchangeEnum = 1
    var userExchangeID = undefined
    
    if (this.userBot.user_exchange && this.userBot.user_exchange.exchange) {
      exchangeEnum = this.userBot.user_exchange.exchange
    }

    if (this.userBot.user_exchange.id) {
      userExchangeID = this.userBot.user_exchange.id
    }

    var formUserBot: UserBotDto = {
      name: this.userBot.name,
      user_exchange_id: userExchangeID,
      base_symbol: this.userBot.base_symbol,
      exchange: exchangeEnum,
      futures: this.userBot.futures_bool ? 1 : 0,
      min_volume: this.userBot.min_volume,
      symbols: this.selectedSymbols,
      notification_providers_enum: this.selectedNotificationProviders,
      conditions: conditions
    }

    // const userBotAddResDto = new UserBotAddResDto()
    // userBotAddResDto.userBot = formUserBot
    // userBotAddResDto.userBotSymbols = this.selectedSymbols

    this.toastr.info(`Adding ${this.userBot.name}`)
    var that = this
    this.botService.addUserBot(formUserBot)
      .subscribe(response => {
        if (response && response.userBot && response.userBot.id > 0) {
          that.toastr.success(`${response.userBot.name} added`)
          this.router.navigate([`/bot/details/${response.userBot.id}`])
        } else if (response && response.error) {
          that.toastr.error(response.error)
        } else {
          that.toastr.error(`Error adding bot`)
        }
      })
  }

  goBack(): void {
    this.location.back()
  }

}

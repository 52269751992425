export class AuthenticatedUser {
    uuid: string
    name: string
    email: string
    record_status: number
    
    token: string
    default_portfolio_id?: number
}

export class LoginResDto
{
    result?: boolean
    error?: string
    user?: AuthenticatedUser
}

export class LoginReqDto {
    email: string
    password: string
}

export class SignupReqDto {
    email: string
    password: string
    name: string
    referral: string
}
